import { DocumentAnswerV2Dto } from "src/backend"
import { useLazyGetDocumentWithDownloadUrlQuery } from "src/services/documentApi";

// hook that provides a function to get download url of a document file
export const useDocumentFileDownloadUrl = () => {
    const [getDocumentDownloadUrl] = useLazyGetDocumentWithDownloadUrlQuery();

    const getDocumentFileDownloadUrl = async (document: Pick<DocumentAnswerV2Dto, 'id' | 'providerType'>, loanId: string): Promise<string> => {
        // get element document as file
        let url = '';
        if (['SHAREPOINT', 'ONE_DRIVE'].includes(document.providerType)) {
            // if provider type is SHAREPOINT we need to append ?download=1 to the url
            // to get the actual file
            url = `/sherpas/api/v1/documents/${document.id}/download?loanId=${loanId}`
        } else {
            // if provider type is AWS we need to get the download url
            const downloadUrl = await getDocumentDownloadUrl({
                id: document.id
            }).unwrap();
            // replace port 5000 with 3000
            // because we map the port 5000 to 3000 in the frontend
            // and if we use use port 5000 we will get a CORS error
            url = downloadUrl.downloadUrl.toString()?.replace('5000', '3000');
        }
        return url;
    }

    return {
        getDocumentFileDownloadUrl
    }
}